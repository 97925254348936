import { lazy } from "react";
import { Route, Routes } from "react-router-dom";
import { checkUAPrefix } from "./helpers/utils/checkUAPrefix";

const SharedLayout = lazy(() => import("./SharedLayout/SharedLayout"));
const Home = lazy(() => import("./pages/Home"));
const Events = lazy(() => import("./pages/Events"));
const OurActivity = lazy(() => import("./pages/OurActivity"));
const About = lazy(() => import("./pages/About"));
const PrivacyPolicy = lazy(() => import("./pages/Privacy-policy"));
const NotFoundContent = lazy(() =>
	import("./modules/NotFound/NotFoundContent")
);
const NewsCard = lazy(() => import("./modules/shared/NewsCard/NewsCard"));

function App() {
	
	checkUAPrefix();
	
	return (
		<Routes>
			<Route
				path='/'
				element={<SharedLayout />}>
				<Route
					index
					element={<Home />}
				/>
				<Route
					path='news'
					element={<Events />}
				/>
				<Route
					path='/events/:id'
					element={<NewsCard />}
				/>
				<Route
					path='/statements/:id'
					element={<NewsCard />}
				/>
				<Route
					path='/researches/:id'
					element={<NewsCard />}
				/>
				<Route
					path='/stories/:id'
					element={<NewsCard />}
				/>
				<Route
					path='/our-activity'
					element={<OurActivity />}
				/>
				<Route
					path='/about-us'
					element={<About />}
				/>
				<Route
					path='/privacy-policy'
					element={<PrivacyPolicy />}
				/>
				<Route
					path='*'
					element={<NotFoundContent />}
				/>
			</Route>
		</Routes>
	);
}

export default App;
